<template>
    <div class="workspace__body">
        <page-header v-if="order" :title="'Заказ №' + order.number" icon="user-secret" :navlinks="navlinks" />
        <fieldset v-if="order" class="order-fs">
            <legend>Детали заказа</legend>
            <ul class="order-list">
                <li class="copy">
                    <span v-if="user.organisation">
                        {{ user.organisation }}<br>
                    </span>
                    {{ user.name }}<br>
                    {{ order.phone }}<br>
                    {{ order.mail }}
                </li>
                <li>Оплата: <b>{{ order.payment }}</b></li>
                <li>Доставка: <b>{{ order.delivery }}</b></li>
                <li>Планируемая дата доставки: <b>{{ order.shippingDate }}</b></li>
                <li>Адрес доставки: <b>{{ order.adres }}</b></li>
                <li>
                    <label for="status">Статус: </label>
                    <select v-model="order.status" name="status" id="status">
                        <option value="Новый заказ">Новый заказ</option>
                        <option value="Принят">Принят</option>
                        <option value="Завершен">Завершен</option>
                    </select>
                    <button @click="update">Сохранить</button>
                </li>
            </ul>
        </fieldset>
        <fieldset v-if="order" class="order-fs">
            <legend>Заказ</legend>
            <table class="order-table">
                <thead>
                    <tr>
                        <th>№</th>
                        <th></th>
                        <th>Название</th>
                        <th>Кол-во</th>
                        <th>Цена</th>
                        <th>Сумма</th>
                    </tr>
                </thead>
                <tfoot>
                    <tr>
                        <td colspan="5">Всего на сумму:</td>
                        <td align="center">{{ getSum() }}</td>
                    </tr>
                </tfoot>
                <tbody>
                    <tr v-for="(item, idx) in order.order.podarki" :key="item.code">
                        <td>{{ idx + 1 }}</td>
                        <td class="td__img"><img :src="item.image" alt=""></td>
                        <td>
                            <p>Подарок №{{ item.art }}</p>
                            <p><b>{{ item.name }}</b></p>
                        </td>
                        <td align="center">{{ item.value }}</td>
                        <td align="center">{{ item.price }}</td>
                        <td align="center">{{ item.value * item.price }}</td>
                    </tr>
                    <tr v-for="(item, idx) in order.order.attachments" :key="item.code">
                        <td>{{ order.order.podarki.length + idx + 1 }}</td>
                        <td class="td__img"><img :src="item.image" alt=""></td>
                        <td>
                            <p>Вложение №{{ item.art }}</p>
                            <p><b>{{ item.name }}</b></p>
                        </td>
                        <td align="center">{{ item.value }}</td>
                        <td align="center">{{ item.price }}</td>
                        <td align="center">{{ item.value * item.price }}</td>
                    </tr>
                    <tr v-for="(item, idx) in order.order.individual" :key="item.code">
                        <td>{{ order.order.podarki.length + order.order.attachments.length + idx + 1 }}</td>
                        <td class="td__img"><img :src="item.image" alt=""></td>
                        <td>
                            <p>Индивидуальный подарок</p>
                            <p>Весом: <b>{{ item.totalWeight }}</b> гр</p>
                            <p class="budget">Бюджет: <b>{{ item.budget }}</b> р</p>
                        </td>
                        <td align="center"><b>{{ item.value }}</b></td>
                        <td align="center">нет</td>
                        <td align="center">нет</td>
                    </tr>
                </tbody>
            </table>
        </fieldset>
        
        <div v-if="order && order.order.individual" class="ind-wrapper">
            <fieldset v-for="(ind, idx) in order.order.individual" :key="ind.code + '-' + idx" class="order-fs">
                <legend>Индивидульный состав № {{ order.order.podarki.length + order.order.attachments.length + idx + 1 }}</legend>
                <div class="ind">
                    <fieldset class="order-fs">
                        <legend>Упаковка</legend>
                        <div class="upak">
                            <div class="image">
                                <img :src="ind.image">
                            </div>
                            <div class="code">Код 1С: {{ ind.code }}</div>
                        </div>
                    </fieldset>
                    <fieldset class="order-fs">
                        <legend>Кондитерское наполнение</legend>
                        <div class="sostav">
                            <div v-for="item in ind.sostav" class="sostav__item" :key="item.code">
                                <div class="image">
                                    <img :src="`http://api.sladkiy-podarochek.ru/conditerka/images/k${item.code}.jpg`" alt="">
                                </div>
                                <div class="qty">
                                    <p>Количество: {{ item.value }} шт.</p>
                                    <p>Код 1С: {{ item.code }}</p>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset class="order-fs">
                        <legend>Для импорта в 1С</legend>
                        <div class="to-programm">
                            Упаковка|{{ ind.code }} <br>
                            <span v-for="item in ind.sostav" :key="item.code">
                                Кондитерка|{{ item.code }}|{{ item.value }}<br>
                            </span>
                        </div>
                    </fieldset>
                </div>
            </fieldset>
        </div>

    </div>
</template>

<script>
import URL from '../../store/url'
import { mapActions } from 'vuex'
export default {
    name: 'Order',
    data() {
        return {
            order: null,
            navlinks: [
                {
                    title: 'Назад к списку заказов',
                    route: '/orders',
                    icon: 'arrow-left'
                }
            ]
        }
    },
    computed: {
        user() {
            const nameArea = this.order.name.split('|')
            const user = {}
            if(nameArea.length === 2) {
                user.organisation = nameArea[0]
                user.name = nameArea[1]
            } else {
                user.organisation = false
                user.name = nameArea[0]
            }
            return user
        }
    },
    methods: {
        getSum() {
            const podarki = this.order.order.podarki.reduce((r, i) => r + i.value * i.price, 0)
            const attachments = this.order.order.attachments.reduce((r, i) => r + i.value * i.price, 0)
            return podarki + attachments
        },
        update() {
            const payload = {
                id: this.order.id,
                status: this.order.status,
            }
            this.updateOrder(payload)
        },
        ...mapActions({
            updateOrder: 'updateOrder'
        })
    },
    async created() { 
        const id = this.$route.params.id
        const response = await fetch(URL.orders + '/' + id, {
            method: 'GET',
            headers: URL.headers()
        })
        const data = await response.json()
        this.order = await data.order
    }
}
</script>